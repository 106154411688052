<template>
  <div class="Support__wrapper">
    <CommonBottomDrawer
      v-if="supportStore.step === 'TYPE_CHOICE'"
      @close="handleSupportClose"
    >
      <template #header>Support</template>
      <template #content>
        <SupportStepTypeChoice @select="handleSelect" />
      </template>
    </CommonBottomDrawer>
    <CommonBottomDrawer
      v-if="supportStore.step === 'REQUEST_ISSUE'"
      @close="handleRequestIssueClose"
    >
      <template #header>
        <div class="Support__header">
          <button class="Support__header-back" @click="handleGoBack">
            <o-icon icon="chevron-left" custom-size="mdi-24px" />
          </button>
          <span class="Support__header-title">Send us your request</span>
        </div>
      </template>
      <template #content>
        <SupportStepRequestIssue ref="formRef" />
      </template>
      <template #footer>
        <button class="Support__button" @click="handleSendRequest">
          Send feedback
        </button>
      </template>
    </CommonBottomDrawer>
    <CommonBottomDrawer
      v-if="supportStore.step === 'REQUEST_SENT'"
      @close="handleSupportClose"
    >
      <template #header>Support</template>
      <template #content>
        <SupportStepRequestSent @close="handleSupportClose" />
      </template>
    </CommonBottomDrawer>
    <CommonBottomDrawer
      v-if="supportStore.step === 'CHAT_SENT'"
      @close="handleSupportClose"
    >
      <template #header>Support</template>
      <template #content>
        <SupportStepChatSent @close="handleSupportClose" />
      </template>
    </CommonBottomDrawer>
    <CommonBottomDrawer
      v-if="supportStore.step === 'REQUEST_ERROR'"
      @close="handleSupportClose"
    >
      <template #header>Support</template>
      <template #content>
        <SupportStepRequestError @try-again="handleTryAgain" />
      </template>
    </CommonBottomDrawer>
  </div>
</template>
<script setup lang="ts">
import { useSupportStore } from "@/store/support";
import { useAuthStore } from "@/store/auth";

const runtimeConfig = useRuntimeConfig();

const supportStore = useSupportStore();
const authStore = useAuthStore();

const device = useDevice();

const formRef = ref();

const handleSelect = (type: "CHAT_SENT" | "REQUEST_ISSUE") => {
  switch (type) {
    case "CHAT_SENT":
      navigateTo(runtimeConfig.public.supportChatUrl, {
        open: {
          target: "_blank",
        },
      });
      supportStore.goToChatSentStep();
      break;
    case "REQUEST_ISSUE":
      supportStore.goToRequestIssueStep();
      break;
  }
};

const handleSupportClose = () => {
  supportStore.goToInitialStep();
};

const handleRequestIssueClose = () => {
  const topic = formRef.value.topic.value;
  const message = formRef.value.message.value;
  supportStore.setFormState({
    topic,
    message,
  });
  handleSupportClose();
};

const handleTryAgain = () => {
  supportStore.goToRequestIssueStep();
};

const handleGoBack = () => {
  // supportStore.goToTypeChoiceStep();
  handleSupportClose();
};

const handleSendRequest = async () => {
  await formRef.value.validateForm();
  const isValid = formRef.value.metaForm.valid;
  if (!isValid) {
    return;
  }
  const topic = formRef.value.topic.value;
  const message = formRef.value.message.value;
  supportStore.setFormState({
    topic,
    message,
  });

  const accessToken = authStore.getCookieAccessToken();
  const headers = {
    "Content-Type": "application/json",
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
  };

  const response = await useAsyncData("supportTicket", () =>
    $fetch(
      `${runtimeConfig.public.backendApiUrl}/public/customer_support/ticket`,
      {
        method: "POST",
        body: {
          topic_id: topic,
          message,
          user_agent: device.userAgent,
        },
        headers,
      },
    ),
  );

  if (response.error.value) {
    supportStore.goToRequestErrorStep();
    return;
  }

  if (response.status.value === "success") {
    supportStore.clearFormState();
    supportStore.goToRequestSentStep();
  }
};
</script>
<style scoped lang="scss">
.Support {
  &__wrapper {
    position: relative;
    z-index: 3;
  }
  &__button {
    @include button-primary;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    position: relative;
    padding-left: 24px;
  }
  &__header-back {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    left: 0;
    cursor: pointer;
  }
}
</style>
