<template>
  <div class="RequestSent__wrapper">
    <IllustrationSupportRequest />
    <h1 class="RequestSent__title">Your request has been sent!</h1>
    <p class="RequestSent__message">
      Our support team will get back to you
      within&nbsp;24&nbsp;hours&nbsp;in&nbsp;KingsChat!
    </p>
    <button class="RequestSent__button" @click="handleClose">
      {{ buttonText }}
    </button>
  </div>
</template>
<script setup lang="ts">
const route = useRoute();

const emit = defineEmits(["close"]);

const handleClose = () => {
  emit("close");
};

const buttonText = computed(() => {
  if (route.path.includes("room")) {
    return "Return to meeting";
  }
  return "Return to home screen";
});
</script>
<style scoped lang="scss">
.RequestSent {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    @include TitleXLarge;
    color: $color-element-dark-strong;
    padding-bottom: 16px;
  }

  &__message {
    @include BodyLarge;
    color: $color-element-dark-medium;
    padding-bottom: 24px;
  }

  &__button {
    @include button-primary;
  }
}
</style>
