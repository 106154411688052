<template>
  <div class="ChatSent">
    <IllustrationActionSuccess />
    <h1 class="ChatSent__title">Thank you for reaching out!</h1>
    <p class="ChatSent__message">
      We're happy to help. Just let us know if there's
      anything&nbsp;else&nbsp;we&nbsp;can&nbsp;do.
    </p>
    <button class="ChatSent__button" @click="handleClose">
      {{ buttonText }}
    </button>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const emit = defineEmits(["close"]);

const handleClose = () => {
  emit("close");
};

const buttonText = computed(() => {
  if (route.path.includes("room")) {
    return "Return to meeting";
  }
  return "Return to home screen";
});
</script>

<style scoped lang="scss">
.ChatSent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    @include TitleXLarge;
    color: $color-element-dark-strong;
    padding-bottom: 16px;
  }

  &__message {
    @include BodyLarge;
    color: $color-element-dark-medium;
    padding-bottom: 24px;
  }

  &__button {
    @include button-primary;
  }
}
</style>
