<template>
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M133.36 104.825C133.51 104.185 133.905 103.63 134.46 103.28C135.015 102.93 135.69 102.815 136.33 102.96L136.69 103.04C137.33 103.185 137.885 103.585 138.235 104.14C138.585 104.695 138.7 105.365 138.555 106.01L135.71 118.465L130.515 117.28L133.36 104.825ZM129.98 104.055L126.465 119.435C126.45 119.495 126.45 119.555 126.46 119.62C126.47 119.68 126.495 119.74 126.525 119.79C126.56 119.84 126.6 119.885 126.65 119.92C126.7 119.955 126.76 119.98 126.82 119.995L137.87 122.52C137.99 122.55 138.12 122.53 138.225 122.46C138.33 122.395 138.405 122.29 138.43 122.17L141.945 106.79C142.12 106.03 142.14 105.24 142.01 104.465C141.88 103.695 141.595 102.955 141.18 102.295C140.765 101.63 140.22 101.06 139.58 100.61C138.94 100.155 138.22 99.835 137.455 99.665L136.51 99.45C136.51 99.45 136.465 99.44 136.445 99.44C133.16 99.17 130.595 101.285 129.96 104.055H129.975H129.98Z"
      fill="#FF7235"
    />
    <path
      d="M123.91 122.23C121.03 134.825 109.665 138.35 109.665 138.35L148.425 138.31L152.97 118.415L118.075 101.55C120.72 104.22 122.645 107.515 123.665 111.14C124.685 114.755 124.77 118.575 123.91 122.235V122.23Z"
      fill="#FF7235"
    />
    <path
      d="M123.91 122.23C124.775 118.57 124.695 114.745 123.675 111.12C122.655 107.5 120.73 104.195 118.08 101.52L109.665 138.345C109.665 138.345 121.03 134.82 123.91 122.225V122.23Z"
      fill="#A15842"
    />
    <path
      d="M104.54 138.31H16.885L0.355025 87.445C-0.869975 83.67 1.19503 80.615 4.96503 80.615H78.965C82.74 80.615 86.795 83.67 88.015 87.445L104.545 138.315L104.54 138.31Z"
      fill="#FFB7D5"
    />
    <path
      d="M159.75 138.31H73.555C73.805 139.075 73.865 139.885 73.74 140.68C73.615 141.475 73.3 142.225 72.83 142.875C72.355 143.525 71.735 144.055 71.02 144.415C70.305 144.78 69.51 144.97 68.71 144.965H154.9C155.705 144.965 156.495 144.78 157.215 144.415C157.93 144.05 158.55 143.52 159.025 142.875C159.5 142.225 159.81 141.475 159.935 140.68C160.06 139.885 159.995 139.075 159.75 138.31Z"
      fill="#FFB7D5"
    />
    <path
      d="M73.56 138.31H16.97L17.63 140.345C18.065 141.69 18.915 142.86 20.06 143.69C21.205 144.52 22.58 144.97 23.99 144.97H68.735C69.535 144.97 70.325 144.775 71.04 144.41C71.755 144.045 72.37 143.515 72.84 142.865C73.31 142.215 73.62 141.465 73.745 140.675C73.87 139.885 73.805 139.075 73.56 138.31Z"
      fill="#CA7358"
    />
    <path
      d="M98.57 138.31H23.615L7.96997 90.225H82.925L98.565 138.31H98.57Z"
      fill="#F5EBDD"
    />
    <path
      d="M120.941 16.6457C102.295 13.0078 84.2306 25.1741 80.5928 43.8197C76.9549 62.4653 89.1211 80.5297 107.767 84.1676C126.412 87.8054 144.477 75.6392 148.115 56.9935C151.752 38.3479 139.586 20.2835 120.941 16.6457Z"
      fill="#FF7235"
    />
    <path
      d="M111.14 66.88C113.51 67.34 115.805 65.795 116.27 63.425C116.735 61.055 115.185 58.76 112.815 58.295C110.445 57.83 108.15 59.38 107.685 61.75C107.22 64.12 108.77 66.415 111.14 66.88Z"
      fill="white"
    />
    <path
      d="M117.85 32.5C120.22 32.96 121.765 35.26 121.305 37.63L117.605 51.39C117.145 53.76 115.825 55.495 113.455 55.035C111.085 54.575 110.515 52.465 110.98 50.095L112.725 35.955C113.185 33.585 115.485 32.04 117.855 32.5H117.85Z"
      fill="white"
    />
    <path
      d="M56.8101 138.31H109.665C109.665 138.31 81.4401 124.145 70.9751 124.145C60.5101 124.145 56.8101 130.49 56.8101 138.31Z"
      fill="#A15842"
    />
    <path
      d="M66.535 115.43L55.185 116.16C50.14 116.485 46.635 111.225 48.875 106.695C51.115 102.165 57.42 101.755 60.225 105.965L66.535 115.43Z"
      fill="#A15842"
    />
  </svg>
</template>
