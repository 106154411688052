<template>
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M133.499 24.7354L37.7335 24.7354C33.6021 24.7354 30.2526 28.0849 30.2526 32.2163V134.104C30.2526 138.235 33.6021 141.585 37.7335 141.585H133.499C137.63 141.585 140.98 138.235 140.98 134.104V32.2163C140.98 28.0849 137.63 24.7354 133.499 24.7354Z"
      fill="#F5EBDD"
    />
    <path
      d="M51.8821 70.6946C51.8821 70.6946 57.348 70.9962 61.4239 68.7037C65.285 66.5318 65.9076 63.1292 64.561 56.3964C64.561 56.3964 66.9742 54.9485 66.7329 53.5006C66.5761 52.5473 63.8371 51.57 63.8371 51.57C63.4944 49.7311 63.06 46.9511 62.3554 44.1084C60.5938 44.4559 58.8007 44.6393 57.0053 44.6586C51.6191 50.7181 45.7381 51.0874 45.7381 51.0874C44.8863 49.8156 43.7304 48.7755 42.3766 48.0612L34.343 51.2635C34.2658 51.5241 34.2031 51.7872 34.1548 52.0526C33.0375 58.3873 40.9117 60.7401 40.9117 60.7401C39.3239 66.8069 34.1548 69.1863 30.2744 70.1202L30.6026 73.1343H52.2875L51.8821 70.6946Z"
      fill="#CA7358"
    />
    <path
      d="M103.821 118.744C122.644 113.193 123.851 68.7904 123.851 68.7904L114.031 56.1549C114.031 56.1549 115.887 47.0717 113.957 45.3824C111.626 43.3433 108.689 49.7262 105.51 57.6897C102.171 66.0563 95.9325 90.6636 95.4499 92.3528C95.4499 92.3528 90.7683 90.152 85.2203 87.594L84.4336 90.181L77.2761 114.31C85.1238 117.522 96.2462 120.978 103.821 118.744Z"
      fill="#CA7358"
    />
    <path
      d="M40.9117 60.7403C40.9117 60.7403 33.0375 58.3874 34.1548 52.0528C34.203 51.7873 34.2658 51.5243 34.343 51.2637L28.491 53.5876C28.491 53.7541 28.5175 53.9109 28.5296 54.0702L30.2744 70.1204C34.162 69.1865 39.3238 66.8071 40.9117 60.7403Z"
      fill="#30242A"
    />
    <path
      d="M45.7381 51.0874C45.7381 51.0874 51.6191 50.7182 57.0053 44.6586C58.8031 44.6393 60.5937 44.4559 62.3553 44.1084C62.0561 42.9018 61.7062 41.6832 61.2911 40.5176L42.3765 48.0612C43.7303 48.7755 44.8862 49.8156 45.7381 51.0874Z"
      fill="#30242A"
    />
    <path
      d="M34.343 51.2633L42.3765 48.061C39.0584 46.3766 35.4169 47.6049 34.343 51.2633Z"
      fill="#CA7358"
    />
    <path
      d="M42.3765 48.0613L61.2911 40.5177C60.6323 38.6619 59.8118 36.9461 58.7693 35.643C54.7296 30.5922 44.8114 26.9266 36.0563 33.3239C27.627 39.4848 28.1411 49.2752 28.491 53.5948L34.343 51.2612C35.4168 47.6052 39.0583 46.3769 42.3765 48.0613Z"
      fill="#30242A"
    />
    <path
      d="M64.9254 83.7523L64.351 78.786C57.7051 76.8482 52.9029 76.7541 52.9029 76.7541L52.2996 73.1343H30.6025L30.9428 76.2714C28.7926 76.2642 26.6473 76.4476 24.5285 76.8192L27.5643 83.7523H64.9254Z"
      fill="#4756DF"
    />
    <path
      d="M33.8797 98.2313H66.6026L65.7652 90.9917H30.7184L33.8797 98.2313Z"
      fill="#4756DF"
    />
    <path
      d="M37.0433 105.471L40.2046 112.711H68.2266L67.9394 109.969L67.9636 109.981L67.4399 105.471H37.0433Z"
      fill="#4756DF"
    />
    <path
      d="M45.4968 123.724C45.4968 123.724 61.2235 124.772 69.6287 126.138L68.982 119.95H43.7906L45.4968 123.724Z"
      fill="#4756DF"
    />
    <path
      d="M65.7652 90.992L64.9254 83.7524H27.5571L30.7184 90.992H65.7652Z"
      fill="#FFB7D5"
    />
    <path
      d="M33.8797 98.2314L37.0434 105.471H67.4399L66.6026 98.2314H33.8797Z"
      fill="#FFB7D5"
    />
    <path
      d="M40.9117 113.589L43.7906 119.95H68.982L68.2266 112.71H40.5015L40.9117 113.589Z"
      fill="#FFB7D5"
    />
    <path
      d="M85.2035 87.5846C82.4621 86.3201 79.5107 84.9591 76.8104 83.7525C74.443 82.6859 72.2663 81.7206 70.594 81.0111C68.5597 80.1448 66.4747 79.4039 64.3511 78.7861L64.9254 83.7525L65.7652 90.9921L66.6026 98.2316L67.4399 105.471L67.9636 109.981C68.2049 110.107 70.331 111.253 73.5526 112.711C74.624 113.193 75.8161 113.715 77.0975 114.241L77.2713 114.311L84.4337 90.1788L85.2204 87.5919L85.2035 87.5846Z"
      fill="#4756DF"
    />
    <path
      d="M43.7906 119.95L40.9117 113.589L40.5014 112.71H40.2046L40.8369 114.158L17.3203 122.711C21.2828 130.602 25.7665 137.373 29.087 140.617C40.6704 151.959 71.0042 144.893 71.0042 144.893C71.0042 144.893 74.4551 147.615 79.0401 148.58C87.245 164.749 91.7577 159.131 91.7577 159.131C91.7577 159.131 94.6535 162.268 96.8254 158.889C101.169 159.131 100.928 156.476 97.0667 147.306C101.41 152.615 105.735 152.699 105.827 151.235C106.068 147.374 95.2085 134.584 85.242 130.414C79.2477 127.906 69.6287 126.137 69.6287 126.137C61.2235 124.784 45.4967 123.724 45.4967 123.724L43.7906 119.95Z"
      fill="#CA7358"
    />
    <path
      d="M24.5285 76.8193C20.0521 77.5867 14.3111 79.4087 10.9954 83.7524C9.5209 85.683 8.52425 88.0962 8.34085 91.1465C7.79305 99.9329 12.0982 112.296 17.3276 122.711L40.8369 114.159L24.5285 76.8193Z"
      fill="#4756DF"
    />
    <path
      d="M47.8376 73.4624L48.3202 77.7193"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.7351 73.4624L44.2178 77.7193"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.8966 71.2037C50.3208 71.0854 48.7787 70.6776 47.3525 69.9971"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M97.588 147.185C96.6589 145.124 95.5947 142.89 94.6367 141.196"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M90.1047 143.6C93.1188 148.894 95.4475 154.855 96.8616 158.878"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M84.757 146.737C86.9289 150.721 89.3976 155.528 91.1785 159.109"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.2532 109.88L67.382 100.886"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M94.9045 92.2563L93.278 98.6272"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.8439 75.7886H33.8386"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.4169 76.7783L53.8705 79.4956"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.0865 123.153L38.4985 122.759"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.3279 123.394L36.2519 103.299"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M114.859 54.0459C114.304 56.2347 113.527 59.1136 112.75 61.3096C112.75 61.3096 116.853 68.5492 112.509 76.9954"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M147.466 132.668H136.995C136.923 132.668 136.855 132.639 136.805 132.588C136.754 132.537 136.725 132.47 136.725 132.397V124.723C136.725 124.651 136.754 124.583 136.805 124.533C136.855 124.482 136.923 124.453 136.995 124.453H147.466C148.555 124.453 149.597 124.885 150.367 125.655C151.137 126.425 151.569 127.467 151.569 128.556C151.569 129.096 151.465 129.629 151.26 130.129C151.055 130.628 150.753 131.082 150.372 131.463C149.99 131.845 149.537 132.149 149.04 132.356C148.54 132.564 148.007 132.67 147.466 132.67V132.668Z"
      stroke="#A15842"
      stroke-width="3.86111"
      stroke-miterlimit="10"
    />
    <path
      d="M133.868 123.203C125.226 123.203 121.23 116.316 121.23 116.316L127.041 141.589H140.695L146.503 116.316C145.154 118.442 143.287 120.189 141.076 121.393C138.866 122.597 136.385 123.22 133.868 123.201V123.203Z"
      fill="#FF7235"
    />
    <path
      d="M133.868 123.203C136.385 123.222 138.866 122.6 141.076 121.396C143.287 120.192 145.154 118.442 146.503 116.318H121.23C121.23 116.318 125.226 123.206 133.868 123.206V123.203Z"
      fill="#A15842"
    />
    <path
      d="M42.5262 55.5178L38.9064 52.8633"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M59.5826 52.5401C60.1352 52.5401 60.5841 52.0912 60.5841 51.5386C60.5841 50.986 60.1352 50.5371 59.5826 50.5371C59.03 50.5371 58.5811 50.986 58.5811 51.5386C58.5811 52.0912 59.03 52.5401 59.5826 52.5401Z"
      fill="#30242A"
    />
    <path
      d="M58.6197 58.3584C58.8611 58.841 60.2052 59.9511 61.5035 59.1958"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M123.074 77.2509C122.49 76.6621 122.041 75.6679 122.162 74.0993C122.162 74.0993 119.99 75.3059 118.542 74.0993C116.64 72.5138 117.335 70.2382 117.335 70.2382C117.335 70.2382 115.163 70.9621 114.198 69.2729C113.151 67.4413 114.439 64.9291 114.439 64.9291C114.439 64.9291 112.704 65.1705 112.267 63.4812C111.712 61.3431 112.873 59.3981 114.094 57.9912"
      stroke="#30242A"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M125.603 91.8026C125.982 92.7992 127.096 93.3012 128.093 92.9223C129.09 92.5434 129.592 91.4285 129.213 90.4319L104.251 24.7158C103.872 23.7192 102.757 23.2172 101.76 23.5961C100.764 23.975 100.262 25.0899 100.641 26.0865L125.603 91.8002V91.8026Z"
      fill="#30242A"
    />
    <path
      d="M91.4039 46.5297L125.637 33.525C127.976 32.637 129.149 30.0235 128.263 27.6851L118.856 2.92091C117.968 0.582529 115.355 -0.590283 113.016 0.295359L78.7829 13.3001C76.4445 14.1881 75.2717 16.8016 76.1574 19.14L85.564 43.9066C86.452 46.245 89.0655 47.4178 91.4039 46.5321V46.5297Z"
      fill="#16B07C"
    />
    <path
      d="M120.784 53.6161C123.03 54.9651 122.548 57.5786 122.548 57.5786C122.548 57.5786 127.068 56.9584 126.935 61.5797C126.935 61.5797 130.538 63.2351 128.885 66.838C128.885 66.838 131.363 67.4847 130.125 71.4448C128.918 75.3059 126.45 77.7408 124.816 77.9604C123.952 78.0763 121.92 77.2365 122.161 74.0993C122.161 74.0993 119.99 75.3059 118.542 74.0993C116.64 72.5138 117.335 70.2382 117.335 70.2382C117.335 70.2382 115.163 70.9622 114.198 69.2729C113.151 67.4413 114.439 64.9292 114.439 64.9292C114.439 64.9292 112.704 65.1705 112.267 63.4813C111.341 59.917 115.185 56.8908 116.129 56.0004L115.405 54.0698C115.405 54.0698 118.542 52.2696 120.784 53.6161Z"
      fill="#CA7358"
    />
    <path
      d="M113.597 65.8003C116.336 64.1883 120.487 61.2152 122.818 57.6582"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M116.778 70.8249C120.757 68.5203 124.259 65.4797 127.101 61.8647"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M122.309 74.4061C124.239 72.9582 126.894 70.3037 129.133 67.251"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M94.2023 27.1482L104.941 30.044L108.802 13.5137"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
