<template>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.1623 36.3113C51.9459 30.6718 47.9277 24.153 45.7448 26.0217C45.2166 26.4764 44.899 27.2486 44.7419 28.2414C44.3173 31.0395 45.2099 35.5959 46.3599 39.7412L42.91 41.2488C42.8874 41.3531 42.8584 41.4558 42.823 41.5564C42.1912 43.4218 40.1153 43.6123 40.1153 43.6123C40.1153 43.6123 42.5523 45.1233 41.4691 47.6004C40.386 50.0776 36.8659 49.2719 36.8659 49.2719C39.1324 51.6922 38.8215 53.4038 37.374 54.5638C37.0783 54.7764 36.733 54.9099 36.3712 54.9516C36.4681 58.0003 36.662 61.4904 37.0163 65.1543L60.1896 57.7998C58.2775 49.877 55.9007 40.734 54.1623 36.3113Z"
      fill="#A15842"
    />
    <path
      d="M45.8551 40.4062C46.0758 41.1049 46.2764 41.7 46.3666 41.9239C46.3666 41.9239 44.4979 46.2898 45.4306 50.9666"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.3742 54.5766C38.8217 53.4166 39.1326 51.705 36.8661 49.2847C36.8661 49.2847 40.3862 50.0804 41.4693 47.6133C42.5524 45.1462 40.1154 43.6251 40.1154 43.6251C40.1154 43.6251 42.7028 43.3978 42.9803 40.8672"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.3788 30.2659C35.736 30.6737 41.7901 30.7406 42.6358 34.6719C43.037 36.5239 40.5599 37.2593 40.5599 37.2593C40.5599 37.2593 43.2342 38.5664 42.9802 40.8563C42.9647 40.9891 42.9412 41.1208 42.91 41.2508L46.3666 39.7398C45.2166 35.5945 44.324 31.0481 44.7486 28.2401L40.6468 19.2141L36.2542 9.54297L26.8939 13.7985L31.2866 23.4697L32.7307 26.6455L34.3788 30.2659Z"
      fill="#EAA58B"
    />
    <path
      d="M40.5598 37.2603C40.5598 37.2603 43.0369 36.5249 42.6358 34.6729C41.79 30.7416 35.7359 30.6614 34.3787 30.2669L32.7407 26.6465C32.6705 26.6465 29.6484 27.1212 28.4283 29.0099C26.5161 31.9718 28.8461 34.0946 28.8461 34.0946C28.8461 34.0946 24.1326 35.7059 26.5295 40.152C26.5295 40.152 23.825 43.535 27.1981 46.2395C27.1981 46.2395 24.644 48.5194 27.977 51.5882C30.7349 54.1355 34.4121 55.2888 36.3811 54.9545C36.743 54.9129 37.0883 54.7794 37.384 54.5668C38.8315 53.4068 39.1424 51.6952 36.8759 49.2749C36.8759 49.2749 40.396 50.0705 41.4791 47.6034C42.5622 45.1363 40.1252 43.6153 40.1252 43.6153C40.1252 43.6153 42.2012 43.4247 42.833 41.5594C42.8684 41.4587 42.8974 41.356 42.9199 41.2518C42.9512 41.1218 42.9746 40.9901 42.9901 40.8573C43.2342 38.5741 40.5598 37.2603 40.5598 37.2603Z"
      fill="#A15842"
    />
    <path
      d="M40.7236 37.2166C37.2235 36.8823 32.2726 36.0432 28.5553 34.0508"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.098 44.3237C37.6615 44.0563 31.9183 43.03 26.7935 40.6465"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.4573 50.2136C36.3312 49.7489 30.812 48.4452 27.1515 46.7637"
      stroke="#30242A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M152.371 89.0025C152.379 88.5306 152.364 88.0587 152.325 87.5884L151.796 84.6199C149.677 77.135 142.299 71.4487 139.588 62.4996L130.338 62.5899V62.416L118.37 67.8015L113.724 58.0903H113.64L109.348 67.8015L104.41 62.5531V62.5899C104.41 62.5899 89.4036 63.1247 63.5259 72.1641C63.5259 72.1641 62.0817 65.5617 60.2097 57.8262L37.0364 65.1807C38.4036 79.2712 42.1912 95.889 52.3003 99.613C64.1477 103.976 92.8335 95.558 92.8335 95.558L97.4869 133.848L145.625 132.177L149.971 131.174C149.971 131.174 154.337 129.001 154.337 124.003C154.327 114.964 152.057 104.28 152.371 89.0025Z"
      fill="#3EC1F3"
    />
    <path
      d="M113.637 58.0898H104.988L104.407 62.5527L109.344 67.8011L113.637 58.0898Z"
      fill="#F5EBDD"
    />
    <path
      d="M113.741 58.0898L118.387 67.8011L130.355 62.4156C130.402 61.9209 130.552 60.4098 130.753 58.2737V58.0898H113.741Z"
      fill="#F5EBDD"
    />
    <path
      d="M90.9514 85.5723L92.1315 95.1766"
      stroke="#4756DF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M113.556 78.091C115.021 78.091 116.207 76.9041 116.207 75.44C116.207 73.9759 115.021 72.7891 113.556 72.7891C112.092 72.7891 110.905 73.9759 110.905 75.44C110.905 76.9041 112.092 78.091 113.556 78.091Z"
      fill="#30242A"
    />
    <path
      d="M44.3407 73.7238C45.8048 73.7238 46.9916 72.5369 46.9916 71.0728C46.9916 69.6087 45.8048 68.4219 44.3407 68.4219C42.8766 68.4219 41.6897 69.6087 41.6897 71.0728C41.6897 72.5369 42.8766 73.7238 44.3407 73.7238Z"
      fill="#30242A"
    />
    <path
      d="M64.1844 72.3242L65.3846 78.0875"
      stroke="#4756DF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M113.64 58.0897H113.744L113.714 58.0262H130.763V58.0897H130.786C130.926 56.5821 131.097 54.7836 131.277 52.8413C128.349 51.8585 121.031 48.9234 118.464 43.5011C122.786 42.0603 127.824 35.936 124.227 30.8915C119.453 24.2056 111.26 33.0544 111.26 33.0544C111.26 33.0544 106.215 29.8118 103.695 22.9688C102.615 23.3264 99.3724 23.6875 96.8518 22.9688C96.8518 22.9688 95.7687 25.8504 95.05 30.8915C94.9497 31.5869 94.8382 32.2554 94.7157 32.8973C94.7157 32.8973 91.4496 32.7001 91.0919 34.1409C90.644 35.9227 93.5022 37.8181 93.5022 37.8181C92.3823 41.8296 91.453 44.9352 92.8904 48.5323C94.1608 51.7047 96.8552 55.3753 105.5 54.2955L105.015 58.0162H113.68L113.64 58.0897Z"
      fill="#A15842"
    />
    <path
      d="M103.681 22.9686C106.202 29.8116 111.247 33.0542 111.247 33.0542C111.247 33.0542 119.44 24.2088 124.214 30.8914C127.814 35.9359 122.773 42.0601 118.451 43.5009C121.018 48.9232 128.336 51.8583 131.264 52.8411C131.896 45.9714 132.658 37.273 132.859 33.0542C133.193 26.1778 132.524 16.0921 121.332 11.0811C110.886 6.40094 101.161 13.6016 101.161 13.6016C101.161 13.6016 92.2787 11.0443 91.8006 16.8443C91.4396 21.1667 96.8451 22.9686 96.8451 22.9686C99.359 23.6873 102.608 23.3263 103.681 22.9686Z"
      fill="#30242A"
    />
    <path
      d="M105.721 54.9532C107.439 54.6557 109.846 54.0239 111.785 52.6934"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M96.4206 41.1482C97.019 41.5192 99.2487 41.8168 100.155 40.0918"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M102.5 33.5C103.328 33.5 104 32.8284 104 32C104 31.1716 103.328 30.5 102.5 30.5C101.672 30.5 101 31.1716 101 32C101 32.8284 101.672 33.5 102.5 33.5Z"
      fill="#30242A"
    />
    <path
      d="M97 32C97.8284 32 98.5 31.3284 98.5 30.5C98.5 29.6716 97.8284 29 97 29C96.1716 29 95.5 29.6716 95.5 30.5C95.5 31.3284 96.1716 32 97 32Z"
      fill="#30242A"
    />
    <path
      d="M119.53 35.2148L115.569 37.7354"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M113.556 78.091C115.021 78.091 116.207 76.9041 116.207 75.44C116.207 73.9759 115.021 72.7891 113.556 72.7891C112.092 72.7891 110.905 73.9759 110.905 75.44C110.905 76.9041 112.092 78.091 113.556 78.091Z"
      fill="#30242A"
    />
    <path
      d="M151.796 84.6191L152.325 87.5877C152.247 86.5831 152.07 85.5887 151.796 84.6191Z"
      fill="#A15842"
    />
    <path
      d="M130.753 58.2741L130.773 58.0898H130.749L130.753 58.2741Z"
      fill="#3EC1F3"
    />
    <path
      d="M104.999 58.0254H104.945L104.938 58.089H104.989L104.999 58.0254Z"
      fill="#F5EBDD"
    />
    <path
      d="M113.637 58.0889L113.663 58.0254H104.998L104.988 58.0889H113.637Z"
      fill="#F5EBDD"
    />
    <path
      d="M104.938 58.0898L104.357 62.4992L104.407 62.5527L104.988 58.0898H104.938Z"
      fill="#F5EBDD"
    />
    <path
      d="M130.746 58.0254H113.71L113.74 58.0889H130.749L130.746 58.0254Z"
      fill="#F5EBDD"
    />
    <path
      d="M74.9187 127.348L67.9586 131.399H81.882L74.9187 127.348Z"
      fill="#30232A"
    />
    <path
      d="M155.988 131.508H107.181C104.966 131.508 103.17 133.304 103.17 135.519C103.17 137.735 104.966 139.531 107.181 139.531H155.988C158.204 139.531 160 137.735 160 135.519C160 133.304 158.204 131.508 155.988 131.508Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0539 110.824H23.5174H11.9809V121.114H23.5174H35.0539H46.5904V110.824H35.0539Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0539 121.113H23.5174H11.9809V131.4H23.5174H35.0539H46.5904V121.113H35.0539Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0539 100.535H23.5174H11.9809V110.825H23.5174H35.0539H46.5904V100.535H35.0539Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0539 79.959H23.5174H11.9809V90.2486H23.5174H35.0539H46.5904V79.959H35.0539Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0539 59.3828H23.5174H11.9809V69.6691H23.5174H35.0539H46.5904V59.3828H35.0539Z"
      fill="#EAA58B"
    />
    <path d="M35.0542 90.248H23.5177V100.538H35.0542V90.248Z" fill="#EAA58B" />
    <path
      d="M35.0542 69.6699H23.5177V79.9595H35.0542V69.6699Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0541 121.114V110.824"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.5177 121.114V110.824"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M35.0539 48.6465H23.5174H11.9809V58.9361H23.5174H35.0539H46.5904V48.6465H35.0539Z"
      fill="#EAA58B"
    />
    <path
      d="M35.0541 58.9361V48.6465"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.5177 58.9361V48.6465"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M11.9809 121.113H23.5174H35.0539H46.5904"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M11.9809 110.824H23.5174H35.0539H46.5904"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M11.9809 59.3828H23.5174H35.0539H46.5904"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.5177 99.9141H27.3621H31.2098H35.0542"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.5177 90.8711H27.3621H31.2098H35.0542"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.5177 79.3379H27.3621H31.2098H35.0542"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.5177 70.2949H27.3621H31.2098H35.0542"
      stroke="#A15842"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M110.531 131.4H4.05165C1.81398 131.4 0 133.214 0 135.452V135.455C0 137.693 1.81398 139.507 4.05165 139.507H110.531C112.769 139.507 114.583 137.693 114.583 135.455V135.452C114.583 133.214 112.769 131.4 110.531 131.4Z"
      fill="#A15842"
    />
    <path
      d="M71.3083 118.62C64.4519 122.05 58.9929 127.98 54.1623 134.208C51.9794 137.013 46.4736 144 50.1074 144.809C52.9154 145.43 58.5282 139.821 58.5282 139.821C58.5282 139.821 55.1485 147.139 57.5922 149.181C59.4609 150.742 61.938 148.248 61.938 148.248C64.121 152.929 67.5508 148.87 67.5508 148.87C73.0533 152.929 78.4622 137.959 78.4622 137.959C81.67 136.817 84.7067 135.243 87.4882 133.279C87.4882 133.279 89.2666 133.74 92.1415 134.308L89.5474 113.281C82.7646 114.535 76.0152 116.267 71.3083 118.62Z"
      fill="#A15842"
    />
    <path
      d="M58.8157 138.414C59.8186 135.773 61.587 131.3 63.1615 128.281"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M68.9049 130.307C66.2974 135.926 64.3886 142.084 63.3255 145.905"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M74.8284 132.176C72.8561 136.689 70.8503 142.796 69.59 146.858"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M131.481 131.401C142.289 122.399 154.28 102.281 155.487 79.9602C156.112 68.4237 147.07 62.5 140.213 62.5H137.709L115.576 110.204C115.576 110.204 102.455 110.896 89.5742 113.276L92.1683 134.303C101.465 136.145 122.211 139.127 131.481 131.401Z"
      fill="#3EC1F3"
    />
    <path
      d="M125.039 89.875L115.916 109.535"
      stroke="#4756DF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M140.698 121.25C142.824 118.286 144.74 115.176 146.431 111.944C147.434 110.032 148.387 108.039 149.266 105.977"
      stroke="#4756DF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M95.4176 111.683C106.279 110.042 112.293 109.844 116.304 109.51C118.979 109.289 122.656 109.176 122.656 109.176"
      stroke="#4756DF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.8371 130.778C101.301 130.778 102.488 129.592 102.488 128.128C102.488 126.663 101.301 125.477 99.8371 125.477C98.373 125.477 97.1862 126.663 97.1862 128.128C97.1862 129.592 98.373 130.778 99.8371 130.778Z"
      fill="#30242A"
    />
    <path
      d="M92.3588 134.94C89.5708 134.385 87.8558 133.938 87.8558 133.938C85.0744 135.901 82.0377 137.476 78.8299 138.618"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
