<template>
  <div class="RequestError__wrapper">
    <DashboardIllustrationError />
    <h1 class="RequestError__title">Oops! Something went wrong.</h1>
    <p class="RequestError__message">
      We couldn't connect to the server. <br />
      Please try sending your request again.
    </p>
    <button class="RequestError__button" @click="handleTryAgain">
      Go back and try again
    </button>
  </div>
</template>
<script setup lang="ts">
const emit = defineEmits(["try-again"]);

const handleTryAgain = () => {
  emit("try-again");
};
</script>
<style scoped lang="scss">
.RequestError {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    @include TitleXLarge;
    color: $color-element-dark-strong;
    padding-bottom: 16px;
  }

  &__message {
    @include BodyLarge;
    color: $color-element-dark-medium;
    padding-bottom: 24px;
  }

  &__button {
    @include button-primary;
  }
}
</style>
