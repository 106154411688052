<template>
  <div class="RequestIssue">
    <div class="RequestIssue__info">
      <div class="RequestIssue__info-item">
        <o-icon
          icon="forum"
          custom-size="mdi-20px"
          class="RequestIssue__info-icon"
        />
        <span>Conversation will be continued on KingsChat</span>
      </div>
      <div class="RequestIssue__info-item">
        <o-icon
          icon="clock-outline"
          custom-size="mdi-20px"
          class="RequestIssue__info-icon"
        />
        <span>We will get back to you within 24 hours</span>
      </div>
      <div class="RequestIssue__info-item">
        <o-icon
          icon="upload"
          custom-size="mdi-20px"
          class="RequestIssue__info-icon"
        />
        <span>You can upload a photo later in the chat</span>
      </div>
    </div>

    <div ref="formWrapper" class="RequestIssue__form">
      <FormDropdown
        ref="topic"
        name="topic"
        label="Topic of issue"
        :options="topicOptions"
        :initial-value="supportStore.formState.topic"
      />
      <FormInput
        ref="message"
        name="message"
        label="Describe your issue"
        type="textarea"
        :initial-value="supportStore.formState.message"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import * as yup from "yup";
import FormDropdown from "@/components/Form/Dropdown/index.vue";
import { mapDataToOptions } from "~/components/Form/Dropdown/helpers";
import { Option } from "~/components/Form/Dropdown/types";
import { useSupportStore } from "~/store/support";

type Topic = { id: string; name: string };
type TopicsResponse = {
  topics: Topic[];
};

const formWrapper = ref();

const topic = ref();
const message = ref();

const topicOptions = ref<Option[]>([]);

const runtimeConfig = useRuntimeConfig();
const supportStore = useSupportStore();

const fetchTopics = async () => {
  const { data } = await useAsyncData<TopicsResponse>("supportTopics", () =>
    $fetch(
      `${runtimeConfig.public.backendApiUrl}/public/customer_support/topics`,
    ),
  );

  if (data.value?.topics) {
    const options = mapDataToOptions({
      data: data.value.topics,
      value: "id",
      label: "name",
    });
    topicOptions.value = options;
  }
};

const { validate: validateForm, meta: metaForm } = useForm({
  validationSchema: yup.object({
    topic: yup.string().required("Please choose a topic"),
    message: yup.string().required("Please enter a description"),
  }),
});

onMounted(() => {
  if (formWrapper.value) {
    formWrapper.value.scrollIntoView({ behavior: "smooth" });
  }
  fetchTopics();
});

defineExpose({
  topic,
  message,
  validateForm,
  metaForm,
});
</script>

<style scoped lang="scss">
.RequestIssue {
  &__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
    border-bottom: 2px solid $color-neutral-100;
  }

  &__info-item {
    @include BodyLarge;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $color-element-dark-medium;
  }

  &__info-icon {
    color: $color-element-dark-weak;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
    @include body-l;
    color: $color-element-dark-strong;
  }

  &__textarea {
    width: 100%;
    min-height: 200px;
    padding: 12px;
    border: 1px solid $color-neutral-300;
    border-radius: 8px;
    background: $color-basic-white;
    @include body-l;
    color: $color-element-dark-strong;
    resize: none;

    &::placeholder {
      color: $color-element-dark-weak;
    }
  }
}
</style>
